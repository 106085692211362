import { QueryResult } from "@apollo/client"
import { DateTime } from "luxon"
import * as React from "react"
import { PoolAllPicksQuery, PoolAllPicksQueryVariables } from "../../../../__generated__/PoolAllPicksQuery"
// import { placeholderImg, teamImgUrlWithFallback, teamLongName, teamName } from "../../../utils/data-utils";
import constants from "../../../../common/constants"
import PickUtils from "../../../../common/pick-utils"
import { entryPicksUrl } from "../../../../common/url-utils"
import ApiRefresher from "../../../Base/ApiRefresher"
import { ButtonSmallBase } from "../../../components/Button"
import ErrorView from "../../../components/ErrorView"
import CloseSvg from "../../../components/icons/Close"
import EmptyPageFillerSvg from "../../../components/icons/EmptyPageFiller"
import LockSvg from "../../../components/icons/Lock"
import PencilSvg from "../../../components/icons/Pencil"
import Link from "../../../components/Link"
import LoadingView from "../../../components/LoadingView"
import TeamImg from "../../../components/TeamImg"
// import { placeholderImg, teamImgUrlWithFallback, teamLongName, teamName } from "../../../utils/data-utils";
import { edgeToNode, getQueryWithError, toRank, formatGamePeriod, isEventInHalftime } from "../../../utils/misc-utils"
import { palette, TDeviceType } from "../../../utils/style-utils"
import GameOgMeta from "../../GameOgMeta"
import { POOL_ALL_PICKS_QUERY } from "../../queries"
import Pagination from "../components/Pagination"
// import PeriodSelect from "../components/PeriodSelect";
import { StandardPoolPageContainer } from "../components/PoolPage"
import StandingsLinks from "../components/StandingsLinks"
import {
  StickyTableColumn,
  StickyTableHeader,
  StickyTableTable,
  StickyTableWrapper,
  StickyTableXWrapper,
  TableRow,
  TruncateText,
} from "../components/StickyTable"
import {
  AllPicksEventCol,
  AllPicksEventContainer,
  AllPicksEventHr,
  AllPicksEventTeamLine,
  AllPicksEventTeamName,
  AllPicksEventTeamRow,
  AllPicksEventTeamScore,
  AllPicksEventTimeLeft,
  AllPicksEventTimeRight,
  AllPicksPlayerHeaderCol,
  AllPicksPointCol,
  AllPicksTableRowHeader,
  StandingsPlayerNameCol,
  StandingsPlayerRankCol,
} from "../styles/AllPicks.styles"
import { CtaModule } from "../styles/Picks.styles"

import { useQuery } from "@apollo/client"
import { CentralTeamsQuery_teams } from "../../../../__generated__/CentralTeamsQuery"
import { EntryDetailsQuery_entry } from "../../../../__generated__/EntryDetailsQuery"
import { PoolPeriodQuery, PoolPeriodQueryVariables } from "../../../../__generated__/PoolPeriodQuery"
import { IPickUtilsGameSettingsValues } from "../../../../common/common-utils-types.d"
import { toTruncatedNumber } from "../../../../common/misc-utils"
import { TPoolRouteProps, IGameOgProps } from "../../../../routes.d"
import useControls, { extractControlsQueryParams, IControls } from "../../../hooks/useControls"
import { buildPollingProps, extractPoolOgProps, extractPoolSettings, gatherPoolSettings } from "../../../hooks/usePoolData"

import { ENUM_HOME, ENUM_INPROGRESS, ENUM_SCHEDULED, ENUM_FINAL, ENUM_HALFTIME, ENUM_NCAAB, ENUM_AWAY } from "../../../../common/enums"
import { GameWeightTypeEnum } from "@cbs-sports/sports-shared-client/build/cjs/utils/enums"

export interface IStandingsProps extends TPoolRouteProps, IPickUtilsGameSettingsValues {
  forSubPoolId?: string
  analyticsTitle?: string
  subPoolName?: string
  header?: React.ReactNode
}

interface IProps extends IStandingsProps, IControls, IGameOgProps {
  poolAllPicksQuery: QueryResult<PoolAllPicksQuery, PoolAllPicksQueryVariables>
  poolPeriodQuery: QueryResult<PoolPeriodQuery, PoolPeriodQueryVariables>
  includedEntryIds: string[]
  canUseTeamLogos: boolean
  isManagerModeActive: boolean
  centralTeams: CentralTeamsQuery_teams[]
  isInComingSoon: boolean
  poolId: string
  entry: EntryDetailsQuery_entry | undefined
  eventsCacheKey: string
  liveStandingsBlockText: string | undefined
  onMount?: () => void
  deviceType?: TDeviceType
  isChallengePool: boolean
}

const slotIdFor = (event, pickUtils: PickUtils) => {
  if (pickUtils.isBracket()) {
    const matchupForEvent = pickUtils.matchups.find((m) => m.id === event.id || (!!m.event && m.event.id === event.id))
    return (matchupForEvent && matchupForEvent.id) || null
  } else {
    return event.id
  }
}
const pickedTeamFor = (event, entry, pickUtils: PickUtils, tiebreakerFields) => {
  if (pickUtils.isBracket()) {
    const slotId = slotIdFor(event, pickUtils)
    const pick = slotId && entry.picks.find((pp) => pp.slotId === slotId)
    return (pick && pickUtils.teams.find((t) => t.id === pick.itemId)) || null
  } else {
    const isTiebreakerButNoPicks = pickUtils.filteredEvents.includes(event)

    if (isTiebreakerButNoPicks) {
      const question = tiebreakerFields.find((q) => q.key === "TOURNAMENT_WINNER")
      const options = (question && question.options) || emptyArray
      const answerValues = entry.tiebreakerAnswers.map((a) => a.value)
      const answerOption = options.find((q) => answerValues.includes(q.value))
      const pickedTeam = answerOption && [event.homeTeam, event.awayTeam].find((t) => !!t && t.id === answerOption.value)
      // if (entry.tiebreakerAnswers.length) {
      //   console.log(`pickedTeam`)
      //   console.dir(question)
      //   console.dir(options)
      //   console.dir(answerValues)
      //   console.dir(answerOption)
      //   console.dir([event.homeTeam, event.awayTeam])
      //   console.dir(pickedTeam)
      // }
      return pickedTeam || (answerOption && { id: answerOption.value, abbrev: answerOption.label }) || null
    }
    const pick = entry.picks.find((pp) => pp.slotId === event.id)
    return (
      (pick &&
        pick.itemId &&
        ((event.homeTeam && pick.itemId === event.homeTeam.id && event.homeTeam) ||
          (event.awayTeam && pick.itemId === event.awayTeam.id && event.awayTeam))) ||
      null
    )
  }
}

// const getGameInstanceFromQuery = (query) => query.data && query.data.gameInstance || null;
const isMyEntry = (myEntryIds: string[], entryId: string) => myEntryIds.indexOf(entryId) > -1

const abbrvFor = (team?: any) => (team && team.abbrev) || "(TBD)"

const hiddenStyle = {
  visibility: "hidden",
}

const AllPicksEventTop = ({ event, canUseTeamLogos, cacheKey, sportType }) => {
  return (
    <AllPicksEventTeamRow>
      <AllPicksEventTeamName>
        <TeamImg
          team={event.awayTeam}
          style={(!event.awayTeam && sportType === ENUM_NCAAB && hiddenStyle) || undefined}
          canUseTeamLogos={canUseTeamLogos}
        />
        {abbrvFor(event.awayTeam)}
      </AllPicksEventTeamName>
      <AllPicksEventTeamScore highlight={event.possession === ENUM_AWAY}>{event.awayTeamScore}</AllPicksEventTeamScore>
    </AllPicksEventTeamRow>
  )
}
const AllPicksEventMid = ({ event, pickUtils, canUseTeamLogos, cacheKey, sportType }) => {
  const spread = pickUtils && pickUtils.getSpreadFor(event)
  return (
    <AllPicksEventTeamRow>
      <AllPicksEventTeamName>
        <TeamImg
          team={event.homeTeam}
          style={(!event.homeTeam && sportType === ENUM_NCAAB && hiddenStyle) || undefined}
          canUseTeamLogos={canUseTeamLogos}
        />
        {abbrvFor(event.homeTeam)}
      </AllPicksEventTeamName>
      {spread && <AllPicksEventTeamLine>({spread})</AllPicksEventTeamLine>}
      <AllPicksEventTeamScore highlight={event.possession === ENUM_HOME}>{event.homeTeamScore}</AllPicksEventTeamScore>
    </AllPicksEventTeamRow>
  )
}
const roundRegex = /Rnd/
const formattedLeftEvt = (event): string => {
  // console.log(`formattedLeftEvt: ${event.gameStatusDesc}`)
  if (event.gameStatusDesc === ENUM_INPROGRESS) {
    return (event.gamePeriod && formatGamePeriod(event.gamePeriod, event.homeTeam?.sportType, event.timeRemaining)) || "-"
  } else if (event.gameStatusDesc === ENUM_SCHEDULED) {
    const startsAtDT = DateTime.fromMillis(event.startsAt)
    return startsAtDT.toFormat("L/dd")
  } else {
    return event.gameStatusDesc
  }
}
const formattedRightDate = (event): string => {
  const isHalftime = isEventInHalftime(event.gamePeriod, event.homeTeam?.sportType, event.timeRemaining)
  if (event.gameStatusDesc === ENUM_FINAL) {
    return " "
  } else if (isHalftime || event.gameStatusDesc === ENUM_HALFTIME) {
    // NOTE qac: they keep a clock in the feed :(
    return " "
  } else if (event.gameStatusDesc === ENUM_INPROGRESS) {
    return event.timeRemaining
  } else if (roundRegex.test(event.gameStatusDesc || "")) {
    // NOTE qac: hack for fake tournament events
    return " "
  } else {
    const startsAtDT = DateTime.fromMillis(event.startsAt)
    return startsAtDT.toFormat("h:mma")
  }
}
const AllPicksEventBot = ({ event, cacheKey }) => {
  return (
    <AllPicksEventTeamRow>
      <AllPicksEventTimeLeft>{formattedLeftEvt(event)}</AllPicksEventTimeLeft>
      <AllPicksEventTimeRight>{formattedRightDate(event)}</AllPicksEventTimeRight>
    </AllPicksEventTeamRow>
  )
}

const emptyPP = { poolRank: constants.DEFAULT_RANK, fantasyPoints: 0 }
const bustedStyle = { color: palette.red }
const emptyArray = [] as never[]

const fakeEntry = { picks: emptyArray }

function AllPicksWrapper(props: IStandingsProps) {
  const { forSubPoolId, subPoolName, analyticsTitle, header, ...baseProps } = props
  const poolData = props.poolData
  const {
    gameInstanceUid,
    poolId,
    isManagerModeActive,
    periodId,
    poolPeriodQuery,
    centralTeams,
    poolDetail,
    detailedEntry,
    isInComingSoon,
    poolDetailIsLoading,
    entryDetailsIsLoading,
    productSeason,
    isBracket,
    deviceType,
  } = poolData
  const { poolScoringMs, liveStandingsBlockText, eventsCacheKey } = buildPollingProps(poolData)
  const entry = detailedEntry
  // const periodParams = extractPeriodParams(poolData);
  const controls = useControls(props)
  const includedEntryIds = entry ? [entry.id] : emptyArray
  const mergedPoolSettings = extractPoolSettings(poolData)
  const ogProps = extractPoolOgProps(poolData)
  const variables = Object.assign(
    {
      poolId,
      gameInstanceUid,
      includedEntryIds,
      forSubPoolId,
      periodId,
      isBracket,
    },
    extractControlsQueryParams(controls),
  )
  // NOTE qac: we want to skip until we know if we need to include the users entryId!
  const skip = !variables.poolId || poolDetailIsLoading || entryDetailsIsLoading
  // if (!skip) {
  //   console.dir(variables)
  // }
  const poolAllPicksQuery = useQuery<PoolAllPicksQuery, PoolAllPicksQueryVariables>(POOL_ALL_PICKS_QUERY, {
    skip,
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  })
  const withError = getQueryWithError([poolAllPicksQuery])
  if (withError) {
    return <ErrorView error={withError.error} refetch={withError.refetch} />
  }
  return (
    <StandardPoolPageContainer
      {...baseProps}
      analyticsFeature="picks"
      analyticsSubfeature={`pickem`}
      analyticsTitle={`Picks - Pick em - ${analyticsTitle || "View All Picks"}`}
    >
      <GameOgMeta gameInstanceUid={gameInstanceUid} seasonType={productSeason?.season} poolName={poolDetail?.name} subPoolName={subPoolName} />
      {!poolAllPicksQuery?.loading && <StandingsLinks {...props} />}
      {skip ? null : <ApiRefresher refetchAfterMs={poolScoringMs} query={poolAllPicksQuery} networkStatus={poolAllPicksQuery.networkStatus} />}
      <AllPicks
        poolAllPicksQuery={poolAllPicksQuery}
        poolPeriodQuery={poolPeriodQuery}
        includedEntryIds={includedEntryIds}
        canUseTeamLogos={!!poolDetail?.canUseTeamLogos}
        isManagerModeActive={isManagerModeActive}
        centralTeams={centralTeams}
        isInComingSoon={isInComingSoon}
        poolId={poolId}
        entry={detailedEntry}
        eventsCacheKey={eventsCacheKey}
        liveStandingsBlockText={liveStandingsBlockText}
        deviceType={deviceType}
        {...props}
        {...ogProps}
        {...controls}
        {...mergedPoolSettings}
        header={header}
        isChallengePool={poolData.isChallengePool}
      />
    </StandardPoolPageContainer>
  )
}

class AllPicks extends React.Component<IProps> {
  public pickUtils: null | PickUtils = null

  public componentDidMount() {
    // account for root treatment for picks page:
    if (this.props.onMount) {
      this.props.onMount()
    }
  }

  public buildPickUtils(period, events, entry) {
    const { centralTeams } = this.props
    if (period && events) {
      this.pickUtils = PickUtils.buildFor(
        entry.picks,
        events,
        period,
        period.matchups || emptyArray,
        centralTeams,
        gatherPoolSettings(this.props),
        undefined,
        this.pickUtils?.gameWeightType === GameWeightTypeEnum.CONFIDENCE ? undefined : this.pickUtils, // NOTE LL: do not want to pass in previous pick utils because mapping of slot ids and picks could be stale, quick fix to get back to brackets
      )
    }
    return this.pickUtils
  }

  public render() {
    const {
      includedEntryIds,
      updateOrder,
      poolPeriodQuery,
      poolAllPicksQuery,
      after,
      goToPage,
      isManagerModeActive,
      isInComingSoon,
      liveStandingsBlockText,
      canUseTeamLogos,
      currentPage,
      header,
      deviceType,
      isChallengePool,
    } = this.props
    // console.dir(poolAllPicksQuery)
    const periodGI = poolPeriodQuery.data?.gameInstance
    const isLoading = !(poolAllPicksQuery && poolAllPicksQuery.networkStatus >= 3)
    const pageInfo = poolAllPicksQuery.data?.gameInstance?.pool?.entries?.pageInfo || ({} as never)

    const period = (periodGI && periodGI.period) || undefined
    // NOTE qac: we need the period to build pickUtils
    const entries = ((!isLoading && period && poolAllPicksQuery.data?.gameInstance.pool.entries.edges) || emptyArray).map(edgeToNode)
    const realEvents = (period && period.events && period.events.edges.map(edgeToNode)) || emptyArray
    const basePickUtils = period && this.buildPickUtils(period, realEvents, fakeEntry)
    if (!basePickUtils || !period) {
      return <LoadingView />
    }
    const isParlay = basePickUtils.isParlay()
    const eventOfThePeriod =
      !!(basePickUtils.totalScoreTiebreakerAttr() || basePickUtils.customTiebreakerAttr()) && basePickUtils.getEventOfThePeriod()
    const eventOfThePeriodId = eventOfThePeriod && eventOfThePeriod.id
    const secondEventOfThePeriod = basePickUtils.getSecondEventOfThePeriod()
    const secondEventOfThePeriodId = secondEventOfThePeriod && secondEventOfThePeriod.id
    const filteredEvents = basePickUtils.filteredEvents
    const events = (basePickUtils.events || emptyArray).concat(filteredEvents)
    const canHaveOverallEntryPoints = !!basePickUtils && basePickUtils.canHaveOverallEntryPoints()
    const rankPrefix = !isParlay || canHaveOverallEntryPoints ? "" : "Proj: "
    const weekLabel = isParlay ? "WK" : "CURR"
    const overallLabel = basePickUtils.isBracket() ? "OVR" : "YTD"
    const tiebreakerFields = basePickUtils.getTiebreakerFields() || emptyArray
    const order = (currentPage && parseInt(currentPage.toString())) || period.order || 1
    const canSeePrevResults = !basePickUtils.isBracket() && order > 1

    if (period) {
      // hack to sort events
      this.buildPickUtils(period, events, fakeEntry)
    }

    // console.log(`AllPicks`);
    // console.dir(period);
    // console.dir(usersEntryIds);
    // if (!!goToPage) {
    //   return (
    //     <StandardPoolPageContainer
    //       subtitle={poolName || pool && pool.name}
    //       title={`Buddy Leaderboard`}
    //       select={(
    //         <PeriodSelect
    //           order={order}
    //           updateOrder={updateOrder}
    //           period={period}
    //           disabled={!!tournamentIds && tournamentIds.length === 1}
    //         />
    //       )}
    //     >
    //       <TodoDiv>
    //       <h2>Start your Buddy Leaderboard</h2>
    //       <h3>Challenge your friends and see how you stack up</h3>
    //       <ButtonBase as="button">Send Challenge</ButtonBase>
    //       </TodoDiv>
    //     </StandardPoolPageContainer>
    //   )
    // }
    if (typeof window !== "undefined" && window.hasOwnProperty("QQ")) {
      // console.dir(pickUtils)
      console.log("rendered")
      console.dir(events)
      window.pickUtils = basePickUtils
    }
    return (
      <>
        {(!events.length && (period.needsEventGroupCategoryApproval || period.needsEventGroupApproval) && (
          <CtaModule
            iconSvg={<EmptyPageFillerSvg sportType={period.segment.sportType} />}
            cta={(canSeePrevResults && <ButtonSmallBase onClick={() => updateOrder(order - 1)}>See Previous Results</ButtonSmallBase>) || null}
          >
            Standings will be available once games begin.
          </CtaModule>
        )) ||
          (!events.length && (
            <CtaModule
              iconSvg={<EmptyPageFillerSvg sportType={period.segment.sportType} />}
              cta={(canSeePrevResults && <ButtonSmallBase onClick={() => updateOrder(order - 1)}>See Previous Results</ButtonSmallBase>) || null}
            >
              No Games for this week.
            </CtaModule>
          )) ||
          (liveStandingsBlockText && (
            <CtaModule
              iconSvg={<EmptyPageFillerSvg sportType={period.segment.sportType} />}
              cta={(canSeePrevResults && <ButtonSmallBase onClick={() => updateOrder(order - 1)}>See Previous Results</ButtonSmallBase>) || null}
            >
              <div dangerouslySetInnerHTML={{ __html: liveStandingsBlockText }} />
            </CtaModule>
          )) || (
            <>
              {header || <StickyTableHeader>Live Leaderboard</StickyTableHeader>}
              <StickyTableWrapper>
                <StickyTableColumn>
                  <AllPicksTableRowHeader>
                    <AllPicksPlayerHeaderCol>Players</AllPicksPlayerHeaderCol>
                  </AllPicksTableRowHeader>
                  {entries.map((entry) => {
                    const periodPoint = entry.periodPoint || emptyPP
                    const rank = periodPoint.poolRank === constants.DEFAULT_RANK ? null : periodPoint.poolRank
                    const bustedText = isParlay && !basePickUtils.isSingleOptionParlay() && periodPoint.fantasyPoints === 0.0 && !!rank && "Busted"
                    const text = bustedText || `${rankPrefix}${toRank(rank) || "-"}`
                    const styleWBusted = bustedText ? bustedStyle : undefined
                    return (
                      <TableRow key={entry.id} as={Link} to={entryPicksUrl(entry, order)}>
                        <StandingsPlayerRankCol highlight={isMyEntry(includedEntryIds, entry.id)} className="mobile__rank" style={styleWBusted}>
                          {text}
                        </StandingsPlayerRankCol>
                        <StandingsPlayerNameCol className="hover__x mobile__name">
                          <TruncateText>{entry.name}</TruncateText>
                        </StandingsPlayerNameCol>
                        {(!isInComingSoon && (
                          <div className="cta">
                            {(isManagerModeActive && <PencilSvg />) || null}
                            <span>{isManagerModeActive ? "Edit" : "View"}</span>
                          </div>
                        )) ||
                          null}
                      </TableRow>
                    )
                  })}
                </StickyTableColumn>
                <StickyTableXWrapper className={`show-scrollbar--${deviceType !== "handheld"}`}>
                  <StickyTableTable className="even-spacing">
                    <AllPicksTableRowHeader>
                      <AllPicksPointCol className="header">
                        <div>Points</div>
                        <div>
                          <small>{weekLabel}</small>
                          <strong>{overallLabel}</strong>
                        </div>
                      </AllPicksPointCol>
                      {events.map((event) => {
                        const cacheKey = basePickUtils.cacheKeyFor(event)
                        const eventContent = (
                          <>
                            <AllPicksEventTop
                              event={event}
                              cacheKey={cacheKey}
                              canUseTeamLogos={canUseTeamLogos}
                              sportType={basePickUtils.sportType}
                            />
                            <AllPicksEventMid
                              pickUtils={basePickUtils}
                              event={event}
                              cacheKey={cacheKey}
                              canUseTeamLogos={canUseTeamLogos}
                              sportType={basePickUtils.sportType}
                            />
                            <AllPicksEventHr />
                            <AllPicksEventBot event={event} cacheKey={cacheKey} />
                          </>
                        )
                        return (
                          <AllPicksEventContainer
                            className={`is-live__${[ENUM_INPROGRESS, ENUM_HALFTIME].includes(event.gameStatusDesc || "")}`}
                            key={`d${event.id}`}
                          >
                            {event.gametrackerLink ? (
                              <a href={event.gametrackerLink} target="_blank">
                                {eventContent}
                              </a>
                            ) : (
                              eventContent
                            )}
                          </AllPicksEventContainer>
                        )
                      })}
                    </AllPicksTableRowHeader>
                    {entries.map((entry) => {
                      const pickUtils = this.buildPickUtils(period, events, entry)
                      if (!pickUtils) {
                        return <TableRow key={entry.id} />
                      }
                      const correctPickSlots = pickUtils.getCorrectPicks().map(({ slotId }) => slotId)
                      // console.log(`correctPickSlots:`, correctPickSlots)
                      return (
                        <TableRow key={entry.id} className={`no-background--${deviceType === "desktop"}`}>
                          <AllPicksPointCol>
                            <small>{toTruncatedNumber(entry.periodPoint?.fantasyPoints) || 0}</small>
                            <strong>{toTruncatedNumber(entry.fantasyPoints) || 0}</strong>
                          </AllPicksPointCol>
                          {events.map((event) => {
                            // const started = hasStarted(event, nowAt);
                            const started = pickUtils.isEventLocked(event.id)
                            const canView = started || isMyEntry(includedEntryIds, entry.id) || isParlay
                            // const team = canView ? pickedTeamFor(event, entry) : null;
                            const team = canView ? pickedTeamFor(event, entry, pickUtils, tiebreakerFields) : null
                            const isTiebreakerButNoPicks = pickUtils.filteredEvents.includes(event)
                            // const isCorrect = !!team && team.id === event.winningTeamId;
                            const slotId = slotIdFor(event, pickUtils)
                            const isCorrect = !!team && correctPickSlots.includes(slotId)
                            const pts = isCorrect && !isTiebreakerButNoPicks && pickUtils.getPossiblePointsFor(slotId)
                            const isTiebreaker = eventOfThePeriodId === event.id || secondEventOfThePeriodId === event.id
                            const tbIndex = secondEventOfThePeriodId ? (eventOfThePeriodId === event.id ? "(1-2)" : "(3-4)") : ""
                            const tbData = pickUtils.getVisibleTiebreakerAnswerValueFor({
                              isForSecondEvent: secondEventOfThePeriodId === event.id,
                              tiebreakerAnswers: entry.tiebreakerAnswers,
                              withSecondEventOfThePeriod: !!secondEventOfThePeriod,
                            })
                            const tbLabel = tbData ? `Tiebreaker ${tbIndex}: ` : ""

                            return (
                              <AllPicksEventCol
                                className={event.gameStatusDesc === "FINAL" && !isTiebreakerButNoPicks ? `is-correct--${isCorrect}` : ""}
                                key={`${entry.id}-${event.id}`}
                              >
                                {((team || isTiebreaker) && (
                                  <>
                                    <div>
                                      {team ? (
                                        <>
                                          <span>{team.abbrev}</span>
                                          {(isCorrect && !isParlay && <sub>({pts}pt)</sub>) || null}
                                        </>
                                      ) : (
                                        <CloseSvg color="#000" />
                                      )}
                                    </div>
                                    {(isTiebreaker && (
                                      <div>
                                        <sub>
                                          {tbLabel}
                                          {tbData}
                                        </sub>
                                      </div>
                                    )) ||
                                      null}
                                  </>
                                )) ||
                                  (canView && <CloseSvg color={palette.injuryRed} />) || <LockSvg />}
                              </AllPicksEventCol>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </StickyTableTable>
                </StickyTableXWrapper>
              </StickyTableWrapper>
              {isLoading && <LoadingView />}
              {!isChallengePool && <Pagination hasPreviousPage={!!after} goToPage={goToPage} isLoading={isLoading} {...pageInfo} />}
            </>
          )}
      </>
    )
  }
}

export default AllPicksWrapper
