import styled from "styled-components/macro"
import { FlexCol, FlexColGrow, FlexRow } from "../../../components/FlexComponents"
import { fontWeight, layout, palette, pxToRem } from "../../../utils/style-utils"
import { IHighlightable, TableHeaderRow } from "../components/StickyTable"

export const leaderboardBreakpointW2 = 750
export const leaderboardBreakpointW = layout.wrapperWidth
export const leaderboardBreakpointWpx = pxToRem(leaderboardBreakpointW)

export const TodoDiv = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  & h2 {
    font-size: 30px;
    padding-bottom: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
  }
  & h3 {
    font-size: 20px;
    padding-bottom: 1.5rem;
  }
`

export const StandingsPlayerNameCol = styled(FlexColGrow)`
  font-weight: ${fontWeight.semiBold};
`
export const StandingsPlayerRankCol = styled(FlexCol)<IHighlightable>`
  height: 100%;
  color: #000;
  font-weight: ${fontWeight.bold};
  min-width: ${pxToRem(90)};
  padding-right: ${pxToRem(4)};
  margin-right: ${pxToRem(22)};
  justify-content: center;
  border-left: ${pxToRem(4)} solid ${({ highlight }) => (highlight ? `${palette.uiNavBlue1}` : `rgba(0,0,0,0)`)};
  @media (max-width: ${leaderboardBreakpointWpx}) {
    justify-content: flex-end;
    min-width: ${pxToRem(50)};
    margin-right: ${pxToRem(8)};
    font-size: ${pxToRem(13)};
    font-weight: ${fontWeight.regular};
  }
  @media (max-width: ${pxToRem(leaderboardBreakpointW2)}) {
    min-width: ${pxToRem(40)};
    font-size: ${pxToRem(11)};
    margin-right: ${pxToRem(6)};
  }
`
export const collapsedW = 120
export const maxW = 140
export const AllPicksColBase = styled(FlexCol)`
  text-transform: uppercase;
  min-width: ${pxToRem(collapsedW)};
  width: ${pxToRem(collapsedW)};
  padding: ${pxToRem(8)} ${pxToRem(6)};
  @media (min-width: ${leaderboardBreakpointWpx}) {
    min-width: ${pxToRem(maxW)};
    width: ${pxToRem(maxW)};
  }
`
export const AllPicksEventHeaderCol = styled(AllPicksColBase)`
  white-space: normal;
  flex-flow: row wrap;
  height: 90%;
  @media (min-width: ${leaderboardBreakpointWpx}) {
    display: block;
    height: ${pxToRem(80)};
  }
  @media (max-width: ${leaderboardBreakpointWpx}) {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }
`
export const AllPicksEventCol = styled(AllPicksColBase)`
  flex-flow: column;
  & > div {
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;
  }
  & > div:not(:first-child) {
    padding-top: ${pxToRem(3)};
  }
  & > div > *:not(:first-child) {
    margin-left: ${pxToRem(6)};
  }
  &.is-correct--true {
    color: ${palette.green};
    font-weight: ${fontWeight.semiBold};
  }
  &.is-correct--false {
    color: ${palette.injuryRed};
    & span {
      text-decoration: line-through;
    }
  }
  & svg {
    width: ${pxToRem(18)};
  }
  & .lock {
    color: #ccc;
  }
  & sub {
    font-size: ${pxToRem(10)};
    color: #000000bf;
    text-decoration: none;
    text-transform: none;
  }
`

export const AllPicksTableRowHeader = styled(TableHeaderRow)`
  height: ${pxToRem(75)};
  overflow: hidden;
  @media (min-width: ${leaderboardBreakpointWpx}) {
    align-items: flex-start;
    height: ${pxToRem(100)};
    position: relative;
  }
`

export const AllPicksEventContainer = styled(AllPicksEventHeaderCol)`
  letter-spacing: ${pxToRem(0.5)};
  font-size: ${pxToRem(12)};
  position: relative;
  overflow: visible;
  &:after {
    display: block;
    content: "";
    background-color: rgba(255, 255, 255, 0.45);
    height: ${pxToRem(30)};
    width: ${pxToRem(1)};
    position: absolute;
    height: ${pxToRem(16)};
    width: ${pxToRem(2)};
    position: absolute;
    bottom: ${pxToRem(-13)};
    left: 50%;
    margin-left: ${pxToRem(-1)};
  }
`
export const AllPicksEventTeamRow = styled(FlexRow)`
  width: 100%;
  @media (min-width: ${leaderboardBreakpointWpx}) {
    background-color: rgba(255, 255, 255, 0.45);
    padding: ${pxToRem(4)} ${pxToRem(8)};
  }
`
export const AllPicksEventHr = styled.div`
  height: ${pxToRem(1)};
  margin: 0 ${pxToRem(8)};
  background-color: rgba(109, 109, 109, 0.3);
  @media (max-width: ${leaderboardBreakpointWpx}) {
    display: none;
  }
`
export const AllPicksEventTeamName = styled.div`
  display: inline-flex;
  align-items: center;
  & .team-img {
    height: ${pxToRem(16)};
    width: ${pxToRem(16)};
    margin-right: ${pxToRem(6)};
  }
`
export const AllPicksEventTeamLine = styled.div`
  padding-left: ${pxToRem(2)};
  font-size: ${pxToRem(8)};
`
export const AllPicksEventTeamScore = styled.div<IHighlightable>`
  flex: 1 1 0%;
  text-align: right;
`
export const AllPicksEventTimeLeft = styled.div`
  flex: 1 1 0%;
  @media (min-width: ${leaderboardBreakpointWpx}) {
    padding: ${pxToRem(4)} 0;
  }
`
export const AllPicksEventTimeRight = styled.div`
  @media (min-width: ${leaderboardBreakpointWpx}) {
    padding: ${pxToRem(4)} 0;
  }
`

export const AllPicksPlayerHeaderCol = styled.div`
  font-size: ${pxToRem(16)};
  text-transform: uppercase;
  align-self: center;
  width: 100%;
  text-align: center;
  left: ${pxToRem(12)};
  bottom: ${pxToRem(8)};
  @media (min-width: ${leaderboardBreakpointWpx}) {
    padding-left: 6vw;
    position: absolute;
    font-weight: ${fontWeight.bold};
    width: auto;
  }
`

export const AllPicksPointCol = styled.div`
  font-size: ${pxToRem(14)};
  min-width: ${pxToRem(80)};
  margin-right: ${pxToRem(6)};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  & small,
  strong {
    width: 50%;
    text-align: center;
  }
  &.header {
    flex-flow: column;
    justify-content: flex-end;
  }
  &.header > *:last-child {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: ${pxToRem(12)};
    padding: ${pxToRem(5)} 0;
  }
  @media (max-width: ${leaderboardBreakpointWpx}) {
    &.header {
      justify-content: center;
    }
  }
`
